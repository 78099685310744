<template>
  <div>
    <div class="top-bg">
      <img src="/image/other/privacy-policy.jpg" alt="" />
    </div>
    <div class="general-faq-warp">
      <h1-title :mb="40"> Privacy Policy </h1-title>
      <div class="general-div">
        <h2 class="general-h2-a">ArcSoft Privacy Policy</h2>
        <p>
          Your privacy is important to ArcSoft. We've developed a Privacy Policy
          that covers how we collect, use, disclose, transfer, and store your
          information. This Privacy Policy governs your use of the ArcSoft web
          site at www.arcsoft.com ("Site") and all other services provided to
          you by ArcSoft (collectively, "Service"). The terms "you," "your," and
          "user" refer to the user of our Service. The terms "ArcSoft," "we,"
          "us," and "our" refer to ArcSoft. By using the Service, you consent to
          the data practices prescribed in this statement.
        </p>
        <h2 class="general-h2-a">Changes to Privacy Policy</h2>
        <p>
          This Privacy Policy may be modified from time to time. We encourage
          you to periodically review our Privacy Policy to stay informed of any
          changes made. Your continued use of our website constitutes your
          agreement to our Privacy Policy and any modifications made thereto.
        </p>
        <h2 class="general-h2-a">Collection and Use of Personal Information</h2>
        <p>
          You may be asked to provide your personal information anytime you use
          the Service or in contact with ArcSoft or our affiliates. ArcSoft and
          its affiliates may share this personal information with each other and
          use it consistent with this Privacy Policy. Here are some examples of
          the types of personal information ArcSoft may collect and how we may
          use it.
        </p>
        <h2 class="general-h2-a">What personal information we collect</h2>
        <p>
          When you register your products, purchase a product, download a
          software update, participate in an online contest, communicate with us
          through email or phone, or otherwise use our Service, we may collect a
          variety of information, including your name, your company, email
          address, region, and contact information.
        </p>
        <h2 class="general-h2-a">How we use your personal information</h2>
        <p>
          The personal information we collect allows us to keep you posted on
          ArcSoft's latest product announcements, software updates, and upcoming
          events. We also use personal information to help us develop, deliver,
          and improve our products, services, content, and advertising. We may
          also use the information that you provide to respond to your requests,
          support your use of ArcSoft products and services, or customize future
          shopping for you.
        </p>
        <p>
          From time to time, we may use your personal information to send
          important notices, such as changes to our terms, conditions, and
          policies. We may also use personal information for internal purposes
          such as auditing, data analysis, and research to improve ArcSoft's
          products, services, and customer communications.
        </p>
        <p>
          From time-to-time we may provide you the opportunity to participate in
          contests or surveys on our Service. Participation in these surveys or
          contests is completely voluntary. If you participate, we will request
          certain personal information from you and we may use the information
          you provide to administer those programs.
        </p>
        <h2 class="general-h2-a">Cookies and Other Technologies</h2>
        <p>
          ArcSoft and our affiliates may use cookies, JavaScript, web beacons,
          and other technologies while providing the Service. When you access
          the ArcSoft Service, we may obtain and store certain types of
          information such as your IP address, time and date of access, language
          use, the type of browser used and the page(s) you request. These
          technologies help us better understand user behavior and tell us which
          parts of our Service people have used.
        </p>
        <p>
          If you want to disable cookies, you can do so by modifying the browser
          settings. Check with your browser provider to find out how to disable
          cookies. Please note that certain features of the ArcSoft Service
          might not be available once cookies are disabled.
        </p>
        <h2 class="general-h2-a">Disclosure to Third Parties</h2>
        <p>
          At times ArcSoft may make certain personal information available to
          third-party affiliates that work with ArcSoft to provide products and
          services, or that help ArcSoft market to customers. Personal
          information will only be shared by ArcSoft to provide or improve our
          products, services and advertising. You can request Arcsoft to stop
          the provision of the personal information to such affiliates. ArcSoft
          will not share or otherwise use your personal information except as
          set forth in this Privacy Policy.
        </p>
        <p>
          ArcSoft may disclose your personal information if required to do so by
          law or in the good faith belief that such action is necessary: (1) to
          comply with any law, legal process, and/or requests from government
          authorities within or outside your country of residence; (2) to
          protect and defend the rights or property of ArcSoft; (3) to enforce
          our Terms of Service; (4) act in urgent circumstances to protect the
          personal safety of users of ArcSoft, the operation of the Service or
          the general public; or (5) for purposes of national security, law
          enforcement, or other issues of public importance, disclosure is
          necessary or appropriate.
        </p>
        <p>
          Additionally, in the event of a reorganization, merger, or sale we may
          transfer any and all personal information we collect to the relevant
          third party.
        </p>
        <h2 class="general-h2-a">Security</h2>
        <p>
          ArcSoft is committed to ensuring the security of your information. To
          prevent unauthorized access or disclosure, maintain data accuracy and
          ensure the appropriate use of information, we have put in place
          appropriate physical, electronic, and managerial procedures to
          safeguard and secure the information we collect online.
        </p>
        <h2 class="general-h2-a">
          Integrity and Retention of Personal Information
        </h2>
        <p>
          ArcSoft makes it easy for you to keep your personal information
          accurate, complete, and up to date. We will retain your personal
          information for the period necessary to fulfill the purposes outlined
          in this Privacy Policy unless a longer retention period is required or
          permitted by law.
        </p>
        <h2 class="general-h2-a">
          How You Can Manage Your Personal Information
        </h2>
        <p>
          If you would like to correct, update, delete, or request access to the
          personal information that you have provided to us, you may contact us
          through the online form available on our website, including your name,
          email address, and purpose of the request.
        </p>
        <h2 class="general-h2-a">Children</h2>
        <p>
          The Service is not intended for or directed to persons under the age
          of 13. We require that use of the Service are by individuals who are
          13 years of age or older. ArcSoft will not knowingly collect
          personally identifiable information from anyone under 13. If ArcSoft
          learn that we have collected the personal information of a child under
          13 we will take steps to delete the information as soon as possible.
        </p>
        <h2 class="general-h2-a">Third-Party Sites and Services</h2>
        <p>
          This Privacy Policy applies only to information ArcSoft collects about
          you as a user of ArcSoft Service. This policy does not apply to
          information about you collected by third party web sites and offerings
          linked to or otherwise accessible from ArcSoft Service. The
          information collected or obtained by these third parties is subject to
          their own privacy policies. Additionally, in the event you access a
          service using ArcSoft Service which is co-branded with a third party
          such third party's privacy policy, in addition to the ArcSoft Privacy
          Policy, may also apply.
        </p>
        <h2 class="general-h2-a">Your California Privacy Rights</h2>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our customers who are California residents to
          request and obtain from us once a year, free of charge, information
          about the personal information (if any) we disclosed to third parties
          for direct marketing purposes in the preceding calendar year. If
          applicable, this information would include a list of the categories of
          personal information that was shared and the names and addresses of
          all third parties with which we shared information in the immediately
          preceding calendar year. California Civil Code Sections 1798.100 to
          1798.199 allow California residents to request that businesses delete
          their personal information (except where retention is required by
          law), correct inaccurate personal information, restrict the use of
          sensitive personal information, and opt out of the sale of their
          personal information. If you are a California resident and would like
          to make such a request, please submit your request to
          <a href="mailto:legal@arcsoft.com">legal@arcsoft.com</a>.
        </p>
        <h2 class="general-h2-a">Privacy Questions</h2>
        <p>
          If you have questions or concerns about ArcSoft's Privacy Policy,
          please contact us at
          <a href="mailto:legal@arcsoft.com">legal@arcsoft.com</a>.
        </p>
        <p>
          <em style="font-style: italic">Last updated: September 1, 2024</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: 'Privacy Policy',
    meta: [
      {
        name: 'description',
        content:
          'Read Privacy Policy to understand how we collect, use, and protect your personal data.',
      },
      {
        name: 'keywords',
        content:
          'Privacy Policy, data protection, personal data, information security, privacy practices, ArcSoft privacy, data collection, user rights, data usage, privacy concerns', // 关键词
      },
    ],
  },
};
</script>
<style lang="less" scoped>
.top-bg {
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
}
.general-faq-warp {
  width: 960px;
  margin: 0 auto;
  padding: 80px 0;
}

.general-h2-a {
  margin-bottom: 8px;
}

p {
  margin-bottom: 40px;
}

.top-bg {
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
  }
}
</style>
